import React from 'react';
import { useTranslate } from '../../../../../customHooks';
import { Tooltip } from '@panwds/react-ui';
import { formatIPPoolType } from '../../../firewallsUtil';
import EnabledStatus from './EnabledStatus';

interface EgressnatProps {
    firewall: any;
    styles: any;
    status: any;
}

const EgressNatOverview: React.FC<EgressnatProps> = ({ firewall, status, styles }) => {
    const translate = useTranslate();
    const egressNatEnabled = firewall?.EgressNAT?.Enabled;
    const egressNatSettings = firewall?.EgressNAT?.Settings;
    const publicIPs = status?.PublicIPs;

    const renderPublicIPs = () => {
        if (!publicIPs?.length) {
            return translate('generic.none');
        }

        const displayIPs = publicIPs.slice(0, 2).map((ip: any) => ip.IPAddress).join(', ');
        const allIPs = publicIPs.map((ip: any) => ip.IPAddress).join(', ');

        if (publicIPs.length > 2) {
            return (
                <Tooltip label={allIPs}>
                    <span className={styles.ipContainer}>
                        {`${displayIPs}...`}
                    </span>
                </Tooltip>
            );
        }

        return displayIPs;
    };

    return (
        <div className={styles.flexContainer}>
            <h2 className={styles.title}>{translate(`resources.firewallsV2.egressNat`)}</h2>
            <div className={styles.flexColumn}>
                <div className={styles.flexRow}>
                    <strong className={styles.flexRowHeader}>{translate(`generic.enabled`)}</strong>
                    <span className={styles.flexRowValues}>
                        <EnabledStatus isEnabled={egressNatEnabled} styles={styles} />
                    </span>
                </div>
                {egressNatEnabled && (
                    <>
                        <div className={styles.flexRow}>
                            <strong className={styles.flexRowHeader}>{translate(`resources.firewallsV2.publicIPType`)}</strong>
                            <span className={styles.flexRowValues}>{egressNatSettings && egressNatSettings.IPPoolType ? formatIPPoolType(egressNatSettings.IPPoolType) : '-'}</span>
                            </div>
                        <div className={styles.flexRow}>
                            <strong className={styles.flexRowHeader}>{translate(`resources.firewallsV2.publicIPs`)}</strong>
                            <span className={styles.flexRowValues}>
                                {renderPublicIPs()}
                            </span>
                        </div>
                    </>
                )}
            </div>
        </div>
    );
};

export default EgressNatOverview;
