import { ApplicationConfigManager, AuthProvider } from './types';
import cognitoApiProvider from "./cognitoProvider";
import oktaApiProvider from "./oktaProvider";
import {fetchJson} from "./utils/fetch";
import * as EndPoints from "./api/FwaasUriEndPoints";
import {IFwaasApiError} from "./api/FwaasDataTypes";
import {isSuccess, buildError} from "./api/utils";

const indexProvider = (httpClient = fetchJson): AuthProvider => ({
    checkUser: (params) => {
        const config = ApplicationConfigManager.getInstance().getConfig();
        let url = config.currentRegion.ApiUrl + EndPoints.URI_CHECK_USER + `?username=${params?.data?.UserName}`
        return httpClient(url).then(async (response) => {
            let responseObj = response.json;
            let ret: {data?:  {ExistsInOkta: string, ExistsInCognito: string}, error?: IFwaasApiError} = {};
            if (isSuccess(responseObj)) {
                let formatResponse = {existsInOkta: "NO", existsInCognito: "NO"};
                formatResponse.existsInOkta = (responseObj.Response.IdpO && responseObj.Response.IdpO.UserStatus) ?
                                            responseObj.Response.IdpO.UserStatus : "NO";
                formatResponse.existsInCognito = (responseObj.Response.IdpC) ?
                                            "YES" : "NO"
                let newAppConfig = ApplicationConfigManager.getInstance().getConfig();
                localStorage.setItem('oktaRegistrationModal', JSON.stringify(formatResponse));
                await ApplicationConfigManager.getInstance().setConfig({...newAppConfig, ...formatResponse}, false, true);
                ret.data = formatResponse as any;
            } else {
                ret.error = buildError(response, "");
            }
            return ret;
        }).catch(err => {
            return { error: buildError(null, err) }
        })
    },
    checkError: (error) => {
        const status = error.status;
        if (status === 401) {
            ApplicationConfigManager.getInstance().clearSession();
            return Promise.reject();
        }
        // other error code (404, 500, etc): no need to log out
        return Promise.resolve();
    },
    checkAuth: () => {
        //console.log("idtoken : " + localStorage.getItem('idToken'));
        if (localStorage.getItem('idToken')) {
            return Promise.resolve(ApplicationConfigManager.getInstance().ensureSession());
        }
        return Promise.reject();
    },
    getPermissions: () => {
        return ApplicationConfigManager.getInstance().getPermissions();
    },
    getIdentity: () => {
        return Promise.resolve({
            id: 'user',
            fullName: ApplicationConfigManager.getInstance().getUserDisplayName(),
        })
    },
    logout: async () => {
        //console.log("inside  authprovider logout");
        await ApplicationConfigManager.getInstance().clearSession();
        return Promise.resolve();
    },
    refreshToken: async (payload: any) => {
        //TODO: Handle both cognito and okta refreshToken
        const CognitoInstance = ApplicationConfigManager.cognitoInstance;
        const OktaInstance = ApplicationConfigManager.oktaInstance;
        const cognitoAppConfig = CognitoInstance.getConfig();
        const oktaAppConfig = OktaInstance.getConfig();

        if (cognitoAppConfig && cognitoAppConfig.user) cognitoApiProvider().refreshToken({...payload});
        if (oktaAppConfig && oktaAppConfig.user) await OktaInstance.refreshSession();
        return Promise.resolve();
    }
});

export const authProvider = indexProvider();
export const cognitoProvider = cognitoApiProvider();
export const oktaProvider = oktaApiProvider();
