import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Skeleton } from '@mui/material';
import { Card, CardHeader, CardBody, Button, Link } from '@panwds/react-ui';
import { find } from "lodash";
import { CircleSolid } from "../../../images";
import { RouteUri } from '../../../routeUri';
import { useHistory } from 'react-router';
import { usePermissions, useTranslate } from '../../../customHooks';
import {PANWDSTooltip} from "../../../components/PANWDSElements";
import {useAppSelector} from "../../../app/hooks";
import { ApplicationConfigManager } from '../../../types';

const useStyles = makeStyles((theme) => ({
    gridStyles: {
        display: 'flex',
        justifyContent: 'space-between',
        flexDirection: 'row',
        padding: '0 0 30px 0',
         '&>div': {
            flexGrow: '1',
            flexBasis: '0',
            minWidth: '0',
        }
    },
    gridStylesSecond: {
        padding: '0 !important',
        alignItems: 'end'
    },
    gridStylesRow: {
        padding: '0 !important',
        display: 'flex',
        flexDirection: 'column',
    },
    rightSide: {
        display: 'flex',
    },
    cardStyles: {
        padding: '0 2px 16px 16px',
    },
    noOfNGFWs: {
        fontFamily: 'Lato',
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: '24px',
        lineHeight: '32px',
        color: "#006FCC",
        cursor: 'pointer'
    },
    noOfNGFWsNoPerm: {
        fontFamily: 'Lato',
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: '18px',
        lineHeight: '32px',
    },
    noOfNGFWsHeader: {
        fontFamily: 'Lato',
        fontStyle: 'normal',
        fontWeight: 600,
        fontSize: '12px',
        lineHeight: '20px',
        color: "#333333",
    },
    noOfNGFWsDef: {
        fontFamily: 'Lato',
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: '12px',
        lineHeight: '16px',
        color: "#707070",
    },
    statusStyles: {
        display: 'flex',
        alignItems: 'center',
        height: '22px',
        // borderBottom: '1px solid #EDEBE9',
        // width: '100%',
        '&>div': {
            width: '15%',
            fontFamily: 'Lato',
            fontStyle: 'normal',
            fontWeight: 400,
            fontSize: '12px',
            lineHeight: '16px',
            color: "#333333",
        },
    },
    svgReady : {
        maxWidth: '30px !important',
        backgroundColor: '#33CCB8',
        webkitMask: `url(${CircleSolid}) no-repeat center`,
        mask: `url(${CircleSolid}) no-repeat center`,
        width: '11px',
        height: '11px'
    },
    svgNotStarted : {
        maxWidth: '30px !important',
        backgroundColor: '#D13C3C',
        webkitMask: `url(${CircleSolid}) no-repeat center`,
        mask: `url(${CircleSolid}) no-repeat center`,
        width: '11px',
        height: '11px'
    },
    svgTerminated : {
        maxWidth: '30px !important',
        backgroundColor: '#797775',
        webkitMask: `url(${CircleSolid}) no-repeat center`,
        mask: `url(${CircleSolid}) no-repeat center`,
        width: '11px',
        height: '11px'
    },
    totalCount: {
        marginLeft: '30%',
        color: "#0078D4 !important",
    },
    horizontalLine: {
        width: '53%',
        color: '#EDEBE9',
    },
    horiStyles: {
        display: 'block',
        height: '1px',
        border: '0',
        borderTop: '1px solid #DADBDB',
        margin: '9px 0 0',
        padding: '0',
    },
    cardHeader: {
        background: "#FFFFFF",
    }
}));

export const FirewallsCard = (props: any) => {
    const classes = useStyles();
    const translate = useTranslate();
    const history = useHistory();
    const reduxState = useAppSelector((state) => state);
    const { permissions } = usePermissions();


    const onClickOfCreateFirewall = () => {
        if (ApplicationConfigManager.getInstance().getConfig().tenantVersion === "V2") {
            history.push(RouteUri.NGFirewallList);
        } else {
            history.push(RouteUri.NGFirewallCreate);
        }
    }

    const onClickOfFirewallsLength  = () => {
        history.push(RouteUri.NGFirewallList)
    }

    return (
        <div data-metrics="cloudngfw-overviewScreen-firewalls-card-div" id="firewalls-card-div" className={classes.cardStyles}>
            <Card data-metrics="cloudngfw-overviewScreen-firewalls-card" dataTestId='ngfw-card'>
                <CardHeader
                    title={translate(`resources.overview.NGFW`)}
                    dataTestId='ngfw-card-header'
                    data-metrics="cloudngfw-overviewScreen-firewalls-card-header"
                    actions={
                        (!props?.readOnly) &&
                        <Button
                            data-metrics="cloudngfw-overviewScreen-firewalls-card-createfirewall"
                            onClick= {() => onClickOfCreateFirewall()}
                            id='quick-tour-id'
                            appearance="secondary"
                            disabled={!(permissions?.CreateFirewall)}>
                            {translate(`resources.overview.create`)}
                        </Button>
                    }
                    addClassName={classes.cardHeader}
                />
                <CardBody data-metrics="cloudngfw-overviewScreen-firewalls-card-body" dataTestId='welcome-card-body'>
                    <div data-metrics="cloudngfw-overviewScreen-firewalls-body">
                        <Grid data-metrics="cloudngfw-overviewScreen-firewalls-card-grid-1" className={classes.gridStyles}>
                            <Grid data-metrics="cloudngfw-overviewScreen-firewalls-card-grid-2" container spacing={3} direction="column"  id="welcome-left-grid">
                                {/* <div className={classes.noOfNGFWs}>{props?.firewallsLength}</div> */}
                                {props.loading
                                ?   <Skeleton variant="text" width="50px" height="30px" />
                                :   props.permission && props.permission.some((permission: any) => permission.Policy === 'LocalFirewallAdmin' || permission.Policy === 'GlobalFirewallAdmin')
                                ?   <Link data-metrics="cloudngfw-overviewScreen-firewalls-card-firewallsLength" className={classes.noOfNGFWs} onClick= {() => onClickOfFirewallsLength()}>
                                        {props?.firewallsLength}
                                    </Link>
                                    : <div className={classes.noOfNGFWsNoPerm}>N/A</div>
                                }
                                <div className={classes.noOfNGFWsHeader}>{translate(`resources.overview.NGFW`)}</div>
                            </Grid>
                            <Grid data-metrics="cloudngfw-overviewScreen-firewalls-card-ngfwsDef" container spacing={3} direction="column" className={classes.gridStylesRow}>
                                <div className={classes.noOfNGFWsDef}>{translate(`resources.overview.ngfwsDef`)}</div>
                            </Grid>
                        </Grid>
                        {/* <Grid className={classnames(classes.gridStyles, classes.gridStylesSecond)}>
                            <Grid className={classes.gridStylesRow}>
                                <div className={classes.statusStyles}>
                                    <div>{translate(`resources.overview.ready`)}</div>
                                    <div className={classes.svgReady}></div>
                                    <div className={classes.totalCount}>
                                        0
                                    </div>
                                </div>
                               <div className={classes.horizontalLine}><hr className={classes.horiStyles}></hr></div>
                                <div className={classes.statusStyles}>
                                    <div>{translate(`resources.overview.notStarted`)}</div>
                                    <div className={classes.svgNotStarted}></div>
                                    <div className={classes.totalCount}>
                                        0
                                    </div>
                                </div>
                                <div className={classes.horizontalLine}><hr className={classes.horiStyles}></hr></div>
                                <div className={classes.statusStyles}>
                                    <div>{translate(`resources.overview.terminated`)}</div>
                                    <div className={classes.svgTerminated}></div>
                                    <div className={classes.totalCount}>
                                        0
                                    </div>
                                </div>
                                <div className={classes.horizontalLine}><hr className={classes.horiStyles}></hr></div>
                            </Grid>
                            <Button onClick= {() => onClickOfCreateFirewall()} id='quick-tour-id' appearance="secondary">{translate(`resources.overview.create`)}</Button>
                        </Grid> */}
                    </div>
                </CardBody>
            </Card>
        </div>
    );
}

export default FirewallsCard;
