import React from 'react';
import { useTranslate } from '../../../../../customHooks';
import EnabledStatus from './EnabledStatus';
interface UserIdProps {
    firewall: any;
    styles: any;
}

const UserIdOverview: React.FC<UserIdProps> = ({ firewall, styles }) => {
    const translate = useTranslate();
    const userIdEnabled = firewall?.UserID?.Enabled;
    const userIdData = firewall?.UserID;

    return (
        <div className={styles.flexContainer}>
            <h2 className={styles.title}>{translate(`resources.firewallsV2.userid.title`)}</h2>
            <div className={styles.flexColumn}>
                <div className={styles.flexRow}>
                    <strong className={styles.flexRowHeader}>{translate(`generic.enabled`)}</strong>
                    <span className={styles.flexRowValues}>
                        <EnabledStatus isEnabled={userIdEnabled} styles={styles} />
                    </span>
                </div>
                {userIdEnabled && (
                    <>
                        <div className={styles.flexRow}>
                            <strong className={styles.flexRowHeader}>{translate(`resources.firewallsV2.userid.userIdStatus`)}</strong>
                            <span className={styles.flexRowValues}>{userIdData?.UserIDStatus}</span>
                        </div>
                        <div className={styles.flexRow}>
                            <strong className={styles.flexRowHeader}>{translate(`resources.firewallsV2.userid.vpcEndpointService`)}</strong>
                            <span className={styles.flexRowValues}>{firewall?.PrivateAccess?.ResourceID}</span>
                        </div>
                        <div className={styles.flexRow}>
                            <strong className={styles.flexRowHeader}>{translate(`resources.firewallsV2.userid.port`)}</strong>
                            <span className={styles.flexRowValues}>{userIdData?.Port}</span>
                        </div>
                        <div className={styles.flexRow}>
                            <strong className={styles.flexRowHeader}>{translate(`resources.firewallsV2.userid.sourceName`)}</strong>
                            <span className={styles.flexRowValues}>{userIdData?.AgentName}</span>
                        </div>
                    </>
                )}
            </div>
        </div>
    );
};

export default UserIdOverview;
