import React, { forwardRef, useMemo } from 'react';
import { Table } from '../../../../../components/PANWDSElements';
import { ColumnOptions, RowInstance } from '@panwds/react-table';
import { useTranslate } from "../../../../../customHooks";
import { Button, Tooltip } from '@panwds/react-ui';
import { DeleteIcon, PlusIcon } from '@panwds/icons';
import { isEmpty } from 'lodash';
import { getEndpointStateDisplay } from '../../../firewallsUtil';

const FirewallEndpointsTable = forwardRef<HTMLInputElement, any>(
    ({ ...props }, ref) => {
    const {
        value,
        setSidePanel,
        deleteEndpoint,
        setEditEndpointData,
        allowListedAwsAccounts,
        endpointAction,
    } = props;

    const translate = useTranslate();

    const columns = useMemo<ColumnOptions<any>[]>(() => [{
        accessor: 'EndpointId',
        Header: translate('resources.firewallsV2.endpointManagement.endpointId'),
        Cell: ({ row }: { row: RowInstance<object> }) => {
            return (row?.original?.Status?.toLowerCase() === "accepted" && (endpointAction !== "firewallUpdating" && endpointAction !== "createEndpoint" && endpointAction !== "deleteEndpoint")) ? <span className='tw-text-blue-600 tw-hover:text-blue-600 tw-cursor-pointer'
              onClick={() => setEditEndpointData(row?.original)}
            >{row?.original?.EndpointId}</span>
              : <span>{row?.original?.EndpointId}</span>
        }
    }, {
      accessor: 'Status',
      Header: translate('resources.firewallsV2.endpointManagement.endpointsStatus'),
      Cell: ({ value }: { value: string }) => {
        {return getEndpointStateDisplay(value)}
      }
    }, {
      accessor: 'Mode',
      Header: "Managed By",
      Cell: ({ value }: { valie: string }) => {
        return (value === "ServiceManaged") ? <span>{translate('generic.cloudNgfw')}</span> :
          <span>{translate('generic.customer')}</span>
    }
    }, {
        accessor: 'AccountId',
        Header: translate('resources.firewallsV2.awsAccountId')
    },{
        accessor: 'VpcId',
        Header: translate('resources.firewallsV2.endpointManagement.vpcId')
    },{
        accessor: 'SubnetId',
        Header: translate('resources.firewallsV2.endpointManagement.subnetId'),
    },{
        accessor: 'actions',
        Header: translate(`generic.actions`),
        Cell: ({ row }: { row: RowInstance<object> }) => {
          return (
            <>
              <Tooltip label={translate(`generic.delete`)}>
                <button
                  className="tw-mr-2 tw-border-none tw-bg-transparent"
                  onClick={() => {
                    deleteEndpoint(row?.original);
                  }}
                  disabled={ endpointAction === "firewallUpdating" || (row?.original?.EndpointId && (row?.original?.Mode === "CustomerManaged" || (endpointAction === "createEndpoint" || endpointAction === "editEndpoint")))}
                >
                  <DeleteIcon
                    className="tw-text-zinc-400 dark:tw-text-gray-600 dark:group-hover:tw-text-gray-400 tw-cursor-pointer"
                    size="sm"
                    data-testid="firewall-edit-endpoint-delete"
                  />
                </button>
              </Tooltip>
            </>
          );
        },
        sticky: true,
      },], []);

    const batchActions = useMemo(() => {
        return {
            rightControl: (
              <Button
                  icon={<PlusIcon size="sm" />}
                  dataTestId="create-endpoint"
                  onClick={() => {setSidePanel('createEndpoint') } }
                  appearance="secondary"
                  disabled={isEmpty(allowListedAwsAccounts) || (endpointAction === "firewallUpdating" || endpointAction === "editEndpoint" || endpointAction === "deleteEndpoint")}
                >
                    {translate(`resources.firewallsV2.endpointManagement.addEndpoint`)}
                </Button>
            ),
        };
    }, [allowListedAwsAccounts]);

    return (
    <div>
        <Table
            tableComponentData={value || []}
            tableComponentColumns={columns}
            tableComponentEmptyStateProps={{
                heading: translate('resources.firewallsV2.endpointManagement.emptyEndpointsTable'),
            }}
            tableBatchAction={batchActions}
            tableComponentFilterBar={{
                hideAddFilter: true,
                filterConfig: undefined,
            }}
            tableTitle={""}
            />
    </div>
  );
    });

export default FirewallEndpointsTable;
