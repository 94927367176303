import React from 'react';
import { useTranslate } from '../../../../../customHooks';
import { BanIcon, CircleCheckIcon } from "@panwds/icons";
import { makeStyles } from '@material-ui/styles';

interface EnabledStatusProps {
    isEnabled: boolean;
    styles: any;
}

const useStyles = makeStyles((theme) => ({
    checkIcon: {
        color: "#33CCB8",
    },
}));

const EnabledStatus: React.FC<EnabledStatusProps> = ({ isEnabled, styles }) => {
    const translate = useTranslate();
    const classes = useStyles();

    return (
        <span className={styles.flexRowValues}>
            {isEnabled ? (
                <div className='tw-flex'>
                    <CircleCheckIcon
                        className={classes.checkIcon}
                        data-metrics="cloudngfw-users-list-CircleCheck-img"
                        size="sm"
                    />{' '}
                    {translate('generic.yes')}
                </div>
            ) : (
                <div className='tw-flex'>
                    <BanIcon
                        size="sm"
                        className="tw-text-red-600 dark:tw-text-dark-bg-red"
                    />{' '}
                    {translate('generic.no')}
                </div>
            )}
        </span>
    );
};

export default EnabledStatus;
