// TODO: remove this code from here
import * as EndPoints from "./FwaasUriEndPoints";
import {ApplicationConfigManager} from "../types";
import EventEmitter from "../eventEmitter";
import {authProvider} from "../authProvider";

const isSuccess = (response: any): boolean => {
    return (response?.ResponseStatus?.ErrorCode === EndPoints.API_STATUS)
}

const buildError = (response: any, exception: any, options: any = {}) => {
    let errInfo;
    if ((response && response.status === 401) || (exception && exception?.body?.status === 401)) {
        console.log("Access Denied with 401, logout triggered :" + response.status);
        ApplicationConfigManager.getInstance().clearSession();
        EventEmitter.emit("logoutUser", () => {});
        authProvider.logout(null);
        errInfo = {
            code: response.status,
            error: response?.json?.message || "Session expired, please login again"
        };
    } else {
        if (response) {
            errInfo = {
                code: response.status,
                error: response?.json?.ResponseStatus?.Reason || "Something went wrong!"
            };
        } else {
            let message = exception?.body?.ResponseStatus?.Reason || exception?.body?.message || exception?.message || exception?.error || exception?.json?.message;
            errInfo = {
                code: 0,
                error: message || exception || "Something went wrong!"
            };
        }
    }
    return { ...errInfo, ...options };
}

const payloadToLowercaseParams = (payload: Record<string, any>) => {
    // Check if the request is empty
    if (!payload || Object.keys(payload).length === 0) {
        return new URLSearchParams();
    }

    // Convert the request keys to lowercase
    const lowerCaseRequest = <Record<string, any>>{};
    Object.keys(payload).forEach((key) => {
        lowerCaseRequest[key.toLowerCase()] = payload[key];
    });

    // Create URLSearchParams object
    const searchParams = new URLSearchParams(lowerCaseRequest);

    return searchParams;
}

export {isSuccess, buildError, payloadToLowercaseParams};
