import { useTranslate } from "../../../../customHooks";
import { Field, FormSpy } from "react-final-form";
import { PANWDSInput, PANWDSSelectWithSearch } from "../../../../components/PANWDSElements";
import { composeValidators, isAlphaNumeric, isRequired, maxLength } from "../../../../utils/validate";
import { FormControl, Grid } from "@material-ui/core";
import { usePermissions } from '../../../../customHooks';
import { Row } from "../../../../components/FormElements";
import { ApplicationConfigManager, SupportedRegion } from "../../../../types";
import { useGetRegionsQuery } from "../../../../redux/services/regions-service";
import { useState } from "react";
import { Tooltip } from "@panwds/react-ui";
import { InfoIcon } from "@panwds/icons";

const Index = (props: any) => {
    const { filteredDeleteProtectionOptions, globalFirewallAdminOption } = props
    const translate = useTranslate();
    const [selectedRegion, setSelectedRegion] = useState('');

    const { data: zones, error, isLoading: isZonesLoading, refetch: refetchZones } = useGetRegionsQuery(selectedRegion, {
        skip: !selectedRegion,
    });

    const handleRegionChange = (value: string) => {
        setSelectedRegion(value);
        if (zones) {
            refetchZones(); // Refetch zones when region changes if the query has been initiated
        }
    };

    const regionItems = ApplicationConfigManager.getInstance().getSupportedRegions().map((region: SupportedRegion) => ({
        label: region.RegionDisplayName,
        value: region.RegionCode
    }));

    return (

            <>
            <Row>
                <Grid item xs={12} sm={12} className="bottomPadding">
                    <FormControl fullWidth>
                        <Field
                            name="FirewallName"
                            // @ts-ignore
                            component={PANWDSInput}
                            title={translate(`resources.firewallsV2.firewallName`)}
                            // required
                            validate={composeValidators(isAlphaNumeric, maxLength(128))}
                            dataMetrics="cloudngfw-firewall-create-name"
                            placeholder="Enter a custom name for this firewall"
                        />
                    </FormControl>
                </Grid>
            </Row>
            <Row>
                <Grid item xs={12} sm={12} className="bottomPadding">
                    <FormSpy subscription={{ values: true }}>
                        {({ form }) => (
                            <FormControl fullWidth>
                                <Field
                                    name="Region"
                                    component={PANWDSSelectWithSearch}
                                    title={translate(`resources.firewallsV2.region`)}
                                    items={regionItems || []}
                                    required
                                    validate={isRequired}
                                    onChange={(event: any) => {
                                        handleRegionChange(event);
                                        form.change('Region', event.target ? event.target.value : event);
                                    }}
                                />
                            </FormControl>
                        )}
                    </FormSpy>
                </Grid>
            </Row>
            <Row>
                <Grid item xs={12} sm={12} className="bottomPadding">
                <FormSpy subscription={{ values: true }}>
                    {({values}) => {
                        // const availabilityZoneChoices = accountsData[values[`AccountId`]]?.availabilityZones || [];
                        return (
                            <FormControl fullWidth>
                                <Field
                                    name="CustomerZoneIdList"
                                    row
                                    // @ts-ignore
                                    component={PANWDSSelectWithSearch}
                                    title={translate(`resources.firewallsV2.availabilityZoneIDs`)}
                                    items={zones}
                                    loading={isZonesLoading}
                                    enableArrayInput
                                    enableMultiSelect
                                    validate={isRequired}
                                    dataMetrics={"cloudngfw-firewall-create-availability-zone"}
                                    required
                                    muted={"Select one or more Availability Zone IDs"}
                                />
                            </FormControl>
                            );
                        }}
                    </FormSpy>
                </Grid>
            </Row>
            <Row>
                <Grid item xs={12} sm={12} className="bottomPadding">
                    <FormSpy subscription={{ values: true }}>
                        {({ values }) => (
                        <FormControl fullWidth>
                            <Field
                                name="DeletionProtection"
                                row
                                // @ts-ignore
                                component={PANWDSSelectWithSearch}
                                title={(
                                    <div className="tw-flex tw-items-center tw-flex-row">
                                        <div>{translate(`resources.firewallsV2.deleteProtection`)}</div>
                                        <div><Tooltip label={translate(`resources.firewallsV2.fwCreate.deleteProtectionTooltip`)}>
                                                <InfoIcon style={{ paddingLeft: "10px" }} size="xs" />
                                            </Tooltip>
                                        </div>
                                    </div>
                                )}
                                items={filteredDeleteProtectionOptions || []}
                                enableArrayInput
                                dataMetrics={"cloudngfw-firewall-create-delete-protection"}
                            />
                        </FormControl>
                        )}
                    </FormSpy>
                </Grid>
            </Row>
            </>
    );
};
export default Index;
