import React from 'react';
import { useTranslate } from '../../../../../customHooks';
import { formatDeletionProtection, getFirewallName, getFirewallStateDisplay } from '../../../firewallsUtil';

interface GeneralInfoProps {
    firewall: any;
    status: any;
    styles: any;
}

const GeneralInfoOverview: React.FC<GeneralInfoProps> = ({ firewall, status, styles }) => {
    const translate = useTranslate();

    return (
        <div className={styles.flexContainer}>
            <h2 className={styles.title}>{translate(`resources.firewallsV2.generalInfo`)}</h2>
            <div className={styles.flexColumn}>
                <div className={styles.flexRow}>
                    <strong className={styles.flexRowHeader}>{translate(`resources.firewallsV2.firewallId`)}</strong>
                    <span className={styles.flexRowValues}>{firewall?.FirewallId}</span>
                </div>
                <div className={styles.flexRow}>
                    <strong className={styles.flexRowHeader}>{translate(`resources.firewallsV2.firewallStatus`)}</strong>
                    <span className={styles.flexRowValues}>{getFirewallStateDisplay(status)}</span>
                </div>
                <div className={styles.flexRow}>
                    <strong className={styles.flexRowHeader}>{translate(`resources.firewallsV2.firewallCommitStatus`)}</strong>
                    <span className={styles.flexRowValues}>{status?.DeviceRuleStackCommitStatus}</span>
                </div>
                <div className={styles.flexRow}>
                    <strong className={styles.flexRowHeader}>{translate(`resources.firewallsV2.firewallName`)}</strong>
                    <span className={styles.flexRowValues}>{getFirewallName(firewall)}</span>
                </div>
                <div className={styles.flexRow}>
                    <strong className={styles.flexRowHeader}>{translate(`resources.firewallsV2.region`)}</strong>
                    <span className={styles.flexRowValues}>{firewall?.Region}</span>
                </div>
                <div className={styles.flexRow}>
                    <strong className={styles.flexRowHeader}>{translate(`resources.firewallsV2.availabilityZoneIDs`)}</strong>
                    <span className={styles.flexRowValues}>{firewall?.CustomerZoneIdList?.join(', ')}</span>
                </div>
                <div className={styles.flexRow}>
                    <strong className={styles.flexRowHeader}>{translate(`resources.firewallsV2.deleteProtection`)}</strong>
                    <span className={styles.flexRowValues}>
                        {firewall?.DeletionProtection ? formatDeletionProtection(firewall.DeletionProtection) : '-'}
                    </span>
                </div>
            </div>
        </div>
    );
};

export default GeneralInfoOverview;
