import React from 'react';
import { useTranslate } from '../../../../../customHooks';
import { CopyButton, Link } from '@panwds/react-ui';
interface EndpointProps {
    firewall: any;
    styles: any;
    handleTabChange: () => void;
}

const EndpointsOverview: React.FC<EndpointProps> = ({ firewall, styles, handleTabChange }) => {
    const translate = useTranslate();
    const endpointsCount = firewall?.Endpoints?.length ?? 0;

    return (
        <div className={styles.flexContainer}>
            <h2 className={styles.title}>{translate(`resources.firewallsV2.endpointManagement.title`)}</h2>
            <div className={styles.flexColumn}>
                <div className={styles.flexRow}>
                    <strong className={styles.flexRowHeader}>{translate(`resources.firewallsV2.userid.vpcEndpointService`)}</strong>
                    <span className={styles.flexRowValues}>
                        {(
                            <div className={styles.truncatedContainer}>
                                <span className={styles.truncatedText}>{firewall?.EndpointServiceName ?? '-'}</span>
                                {firewall?.EndpointServiceName && <CopyButton text={firewall?.EndpointServiceName} />}
                            </div>
                        )}
                    </span>
                </div>
                <div className={styles.flexRow}>
                    <strong className={styles.flexRowHeader}>{translate(`resources.firewallsV2.endpointManagement.numberOfEndpoints`)}</strong>
                    <span className={styles.flexRowValues}>
                    {endpointsCount > 0 ? (
                            <Link onClick={handleTabChange} className="text-blue-500 underline">
                                {endpointsCount}
                            </Link>
                        ) : (
                            endpointsCount
                        )}
                    </span>
                </div>
            </div>
        </div>
    );
};

export default EndpointsOverview;
