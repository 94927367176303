import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Skeleton } from '@mui/material';
import { Card, CardHeader, CardBody, Button, Select, Tooltip, Link, SelectItem } from '@panwds/react-ui';
import { CircleSolid } from "../../../images";
import { RouteUri } from '../../../routeUri';
import { useHistory } from 'react-router';
import { InfoIcon } from '@panwds/icons';
import { find } from 'lodash';
import { usePermissions, useTranslate } from '../../../customHooks';

const useStyles = makeStyles((theme) => ({
    cardStyles: {
        padding: '16px 2px 16px 16px',
    },
    gridStyles: {
        display: 'flex',
        justifyContent: 'space-between',
        flexDirection: 'row',
        padding: '0 0 30px 0',
         '&>div': {
            flexGrow: '1',
            flexBasis: '0',
            minWidth: '0',
        }
    },
    gridStylesSecond: {
        padding: '0 !important',
        alignItems: 'end'
    },
    gridStylesRow: {
        padding: '0 !important',
        display: 'flex',
        flexDirection: 'column',
    },
    rightSide: {
        display: 'flex',
    },
    noOfNGFWs: {
        fontFamily: 'Lato',
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: '24px',
        lineHeight: '32px',
        color: "#006FCC",
        cursor: 'pointer'
    },
    noOfNGFWsNoPerm: {
        fontFamily: 'Lato',
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: '18px',
        lineHeight: '32px',
    },
    noOfNGFWsHeader: {
        fontFamily: 'Lato',
        fontStyle: 'normal',
        fontWeight: 600,
        fontSize: '12px',
        lineHeight: '20px',
        color: "#333333",
    },
    noOfNGFWsDef: {
        fontFamily: 'Lato',
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: '12px',
        lineHeight: '16px',
        color: "#707070",
    },
    statusStyles: {
        display: 'flex',
        alignItems: 'center',
        height: '23px',
        '&>div': {
            width: '15%',
            fontFamily: 'Lato',
            fontStyle: 'normal',
            fontWeight: 400,
            fontSize: '12px',
            lineHeight: '16px',
            color: "#333333",
        },
    },
    svgReady : {
        maxWidth: '30px !important',
        backgroundColor: '#33CCB8',
        webkitMask: `url(${CircleSolid}) no-repeat center`,
        mask: `url(${CircleSolid}) no-repeat center`,
        width: '11px',
        height: '11px'
    },
    svgNotStarted : {
        maxWidth: '30px !important',
        backgroundColor: '#D13C3C',
        webkitMask: `url(${CircleSolid}) no-repeat center`,
        mask: `url(${CircleSolid}) no-repeat center`,
        width: '11px',
        height: '11px'
    },
    svgTerminated : {
        maxWidth: '30px !important',
        backgroundColor: '#797775',
        webkitMask: `url(${CircleSolid}) no-repeat center`,
        mask: `url(${CircleSolid}) no-repeat center`,
        width: '11px',
        height: '11px'
    },
    totalCount: {
        marginLeft: '30%',
        color: "#0078D4 !important",
    },
    horizontalLine: {
        width: '53%',
        color: '#EDEBE9',
    },
    horiStyles: {
        display: 'block',
        height: '1px',
        border: '0',
        borderTop: '1px solid #DADBDB',
        margin: '9px 0 0',
        padding: '0',
    },
    leftGrid1: {
        paddingRight: '30%'
    },
    rulestackIcon: {
        display: 'flex',
        justifyContent: 'center',
        '&>span': {
            '&>svg': {
                marginLeft: '5px',
                marginTop: '3px'
            }
        },
    },
    cardHeader: {
        background: "#FFFFFF",
    }
}));

export const RulestacksCard = (props: any) => {
    const classes = useStyles();
    const translate = useTranslate();
    const history = useHistory();
    const { permissions } = usePermissions();

    const getRuleStackRoutes = (selectedItem: any) => {
        history.push(RouteUri.RuleStackCreate + `?scope=${selectedItem.value}`)
    }

    const onClickOfLocalRuleStackLength = () => {
        history.push(RouteUri.RuleStackList + "?search=Local")
    }

    const onClickOfGlobalRuleStackLength  = () => {
        history.push(RouteUri.RuleStackList + "?search=Global")
    }

    const getItems = () => {
        let itemsArray = [];
        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
        ((props.permission && find(props.permission, { 'Policy': 'GlobalRuleStackAdmin' }))) && itemsArray.push({label: translate(`resources.overview.global`), value: 'Global'});

        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
        ((props.permission && (find(props.permission, { 'Policy': 'LocalRuleStackAdmin' }) || find(props.permission, { 'Policy': 'GlobalFirewallAdmin' })))) && itemsArray.push({label: translate(`resources.overview.local`), value: 'Local'});
        return itemsArray;
    }

    return (
        <div data-metrics="cloudngfw-overviewScreen-rulestacks-card-div" id="rulestacks-card-div" className={classes.cardStyles}>
            <Card dataTestId='rulestacks-card' data-metrics="cloudngfw-overviewScreen-rulestacks-card-container" >
                <CardHeader
                    title={translate(`resources.overview.rulestacks`)}
                    dataTestId='rulestacks-card-header'
                    data-metrics="cloudngfw-overviewScreen-rulestacks-card-header"
                    addClassName={classes.cardHeader}
                    actions={
                        <>
                        {(permissions?.CreateRuleStack && !props?.readOnly) &&
                         <Select
                                    button={
                                    <Button dataMetrics="cloudngfw-overviewScreen-rulestacks-button" isMenu appearance="secondary" disabled={!(permissions?.CreateRuleStack)}>
                                        {translate(`resources.overview.create`)}
                                    </Button>
                                    }
                                    items={getItems()}
                                    onChange={({ selectedItem }) => getRuleStackRoutes(selectedItem)}
                                    dataMetrics="cloudngfw-overviewScreen-rulestacks-card-dropdown"
                                />
                        }
                        </>
                    }
                />
                <CardBody  data-metrics="cloudngfw-overviewScreen-rulestacks-card-body" dataTestId='rulestacks-card-body'>
                    <div data-metrics="cloudngfw-overviewScreen-rulestacks-body">
                        <Grid  data-metrics="cloudngfw-overviewScreen-rulestacks-card-gridStyles"  className={classes.gridStyles}>
                        <Grid  data-metrics="cloudngfw-overviewScreen-rulestacks-card-gridStyles-container" container spacing={3} direction="row">
                            <Grid data-metrics="cloudngfw-overviewScreen-rulestacks-left-grid-1" className={classes.leftGrid1} id="rulestacks-left-grid-1">
                                {/* <div className={classes.noOfNGFWs}>{props?.globalRuleStacksLength}</div> */}
                                {props.loading
                                    ? <Skeleton variant="text" width="50px" height="30px" />
                                    : props?.globalRuleStacksLength ? <Link className={classes.noOfNGFWs} onClick={onClickOfGlobalRuleStackLength}>
                                        {props?.globalRuleStacksLength}
                                    </Link>
                                    : <div className={classes.noOfNGFWsNoPerm}>N/A</div>
                                }
                                <div className={classes.rulestackIcon}>
                                    <span className={classes.noOfNGFWsHeader}>{translate(`resources.overview.global`)}</span>
                                    <Tooltip data-metrics="cloudngfw-overviewScreen-rulestacks-card-global-tooltip" key={"secondRowCardHeader-global"} label={translate(`resources.overview.globalTooltip`)}>
                                        <span> <InfoIcon className="tw-text-gray-400 dark:tw-text-dark-bg" size="sm" /></span>
                                    </Tooltip>
                                </div>
                            </Grid>
                            <Grid data-metrics="cloudngfw-overviewScreen-rulestacks-left-grid-2" id="rulestacks-left-grid-2">
                                {/* <div className={classes.noOfNGFWs}>{props?.localRuleStacksLength}</div> */}
                                {props.loading
                                    ? <Skeleton variant="text" width="50px" height="30px" />
                                    : props.permission && (find(props.permission, { 'Policy': 'LocalRuleStackAdmin' }) || find(props.permission, { 'Policy': 'GlobalFirewallAdmin' }))
                                    ? <Link className={classes.noOfNGFWs} onClick={onClickOfLocalRuleStackLength}>
                                        {props?.localRuleStacksLength}
                                    </Link>
                                    : <div className={classes.noOfNGFWsNoPerm}>N/A</div>
                                }
                                <div className={classes.rulestackIcon}>
                                    <span className={classes.noOfNGFWsHeader}>{translate(`resources.overview.local`)}</span>
                                    <Tooltip data-metrics="cloudngfw-overviewScreen-rulestacks-card-local-tooltip" key={"secondRowCardHeader-local"} label={translate(`resources.overview.localTooltip`)}>
                                        <span> <InfoIcon className="tw-text-gray-400 dark:tw-text-dark-bg" size="sm" /></span>
                                    </Tooltip>
                                </div>
                            </Grid>
                            </Grid>
                            <Grid data-metrics="cloudngfw-overviewScreen-rulestacks-noOfNGFWsDef" container spacing={3} direction="column" className={classes.gridStylesRow}>
                                <div className={classes.noOfNGFWsDef}>{translate(`resources.overview.rulestacksDef`)}</div>
                            </Grid>
                        </Grid>
                        {/* <div className={classnames(classes.gridStyles, classes.gridStylesSecond)}>
                            <div className={classes.gridStylesRow}>
                                <div className={classes.statusStyles}>
                                    <div>{translate(`resources.overview.success`)}</div>
                                    <div className={classes.svgReady}></div>
                                    <div className={classes.totalCount}>
                                        0
                                    </div>
                                </div>
                               <div className={classes.horizontalLine}><hr className={classes.horiStyles}></hr></div>
                                <div className={classes.statusStyles}>
                                    <div>{translate(`resources.overview.failure`)}</div>
                                    <div className={classes.svgNotStarted}></div>
                                    <div className={classes.totalCount}>
                                        0
                                    </div>
                                </div>
                                <div className={classes.horizontalLine}><hr className={classes.horiStyles}></hr></div>
                                <div className={classes.statusStyles}>
                                    <div>{translate(`resources.overview.pending`)}</div>
                                    <div className={classes.svgTerminated}></div>
                                    <div className={classes.totalCount}>
                                        0
                                    </div>
                                </div>
                                <div className={classes.horizontalLine}><hr className={classes.horiStyles}></hr></div>
                            </div>
                            <span>
                                <Select
                                    button={
                                    <Button isMenu appearance="secondary">
                                        {translate(`resources.overview.create`)}
                                    </Button>
                                    }
                                    items={[
                                    { value: translate(`resources.overview.global`) },
                                    { value: translate(`resources.overview.local`) },
                                    ]}
                                    onChange={({ selectedItems }) => getRuleStackRoutes(selectedItems)}
                                    // selectedItem={[]}
                                />
                            </span>
                        </div> */}
                    </div>
                </CardBody>
            </Card>
        </div>
    );
}

export default RulestacksCard;
