import { EventEmitter } from "@fawkes/ui-product-help";
import { Box, Theme } from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import {
    BottomNav,
    Navigation,
    NavItem,
    TopNav,
    SubNavList,
    SubNavListItem,
} from "@panwds/react-nav";
import MessageIcon from "@material-ui/icons/Message";
import NewReleasesIcon from "@material-ui/icons/NewReleasesRounded";
import { FC, ReactNode, useCallback, useEffect, useState } from "react";
import { useLogout, useTranslate, usePermissions } from "../customHooks";
import { useSelector } from 'react-redux';
import { MiniLogo, Logo } from '../images/index';
import AccordionWrapper from './components/AccordionWrapper';
import { ApplicationConfigManager, AppState } from '../types';
import classnames from "classnames";
import {
    ProfileNavigationIcon,
    SettingsIcon,
    DashboardLayoutIcon,
    HelpIcon,
    ServerIcon,
    ShieldIcon,
} from "@panwds/icons";
import './Menu.css';
import { Tag } from "@panwds/react-ui";

export interface MenuProps {
    children?: ReactNode;
    classes?: object;
    className?: string;
    dense?: boolean;
    hasDashboard?: boolean;
    /**
     * @deprecated
     */
    logout?: ReactNode;
    /**
     * @deprecated
     */
    onMenuClick?: () => void;
}

type MenuName = "manage" | "accounts" | "settings" | "profile";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    marginTop: theme.spacing(1),
  },
  sideBar: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    height: "100%",
    width: "250px",
  },
  logo: {
    padding: "13px",
  },
  topOpts: {},
  bottomOpts: {
    marginBottom: theme.spacing(2),
  },
  icon: {
    //color: theme.palette.primary.contrastText,
    height: 18,
    marginLeft: "-5px",
  },
  active: {
    color: theme.palette.primary.contrastText,
    background: "#475566",
  },
  logoutButton: {
    padding: "0.625rem 1rem 0.625rem 4rem",
  },
}));

const Menu: FC<MenuProps> = ({ dense = false }) => {

  const translate = useTranslate();
  const { permissions } = usePermissions();

  /*const isXSmall = useMediaQuery((theme: Theme) =>
      theme.breakpoints.down('xs')
  );*/
  // const open = useSelector((state: AppState) => state.admin.ui.sidebarOpen);
  useSelector((state: AppState) => state.theme); // force rerender on theme change

  const classes = useStyles();
  const classesMenu = {
    root: classes.root,
    active: classes.active,
    icon: classes.icon,
  };

  const config = ApplicationConfigManager.getInstance().getConfig();
  const [userDisplayName, setUserDisplayName] = useState(
    config.userDisplayName
  );

  const logout = useLogout();
  const handleLogout = useCallback(() => {
    //ApplicationConfigManager.getInstance().clearSession();
    logout(null, "", true);
  }, [logout]);

  useEffect(() => {
    if (config.userDisplayName) {
        setUserDisplayName(config.userDisplayName);
    } else if (!config.userDisplayName) {
      ApplicationConfigManager.getInstance().ensureUserDisplayName();
    }
  }, [config.userDisplayName]);

  return (
    <Box style={{ zIndex: 5, fontFamily: "Lato !important" }}>
      <Navigation
        logoUrl={"/"}
        logo={MiniLogo}
        fullName={Logo}
        hasCollapseController={true}
        isTwoLines={true}
      >
        <TopNav>
          <NavItem
            title={translate("resources.overview.name")}
            url={`/overview`}
            dataMetrics={"cloudngfw-menu-manage"}
            icon={<DashboardLayoutIcon size="md" />}
            id="overview"
          ></NavItem>
          <NavItem
            title={translate("resources.ruleStacks.name")}
            url="/rulestacks"
            dataMetrics={"cloudngfw-menu-manage-rulestacks"}
            id="rulestacks"
            icon={<ShieldIcon size="md" />}
          ></NavItem>
          <NavItem
            title={translate("resources.firewalls.name")}
            url="/ngfirewalls"
            dataMetrics={"cloudngfw-menu-manage-firewalls"}
            id="ngfirewalls"
            icon={<ServerIcon size="md" />}
          ></NavItem>
          <AccordionWrapper>
            <SubNavList
              isSimpleNav
              defaultOpen={true}
              icon={<SettingsIcon size="md" />}
              title="Settings"
              id="settings"
              url="/setting"
              dataMetrics={"cloudngfw-menu-settings"}
            >
              <SubNavListItem
                isSimpleNav
                title={translate("resources.users.name")}
                url="/users"
                dataMetrics={"cloudngfw-menu-settings-users"}
              />
              <SubNavListItem
                isSimpleNav
                title={translate("resources.accounts.name")}
                url="/accounts"
                dataMetrics={"cloudngfw-menu-settings-accounts"}
              />
              <SubNavListItem
                isSimpleNav
                title={translate("resources.inventory.name")}
                url="/inventory"
                dataMetrics={'cloudngfw-menu-settings-inventory'}
              />
              <SubNavListItem
                isSimpleNav
                title={translate("resources.tokens.name")}
                url="/tenant"
                dataMetrics={"cloudngfw-menu-settings-tenant"}
              />
              {permissions?.CreateLinkAccount && (
                <SubNavListItem
                  isSimpleNav
                  url="/integrations"
                  title={translate("resources.integrations.name")}
                  dataMetrics="menu-integrations"
                />
              )}
              <SubNavListItem
                isSimpleNav
                title={translate("resources.support.name")}
                url="/subscription"
                dataMetrics={"cloudngfw-menu-settings-subscription"}
              />
              <SubNavListItem
                isSimpleNav
                title={translate("resources.usage.name")}
                url="/usage"
                dataMetrics={"cloudngfw-menu-settings-usage-explorer"}
                badge={<Tag appearance="blue">{translate("resources.usage.beta")}</Tag>}
              />
            </SubNavList>
          </AccordionWrapper>
        </TopNav>

        <BottomNav>
          <NavItem
            isSimpleNav
            title="Get Help"
            id="getHelp"
            onClick={() => EventEmitter.emit("toggleKnowledgeCenter")}
            icon={<HelpIcon size="md" />}
            dataMetrics={"cloudngfw-menu-get-help"}
          />
          <NavItem
            isSimpleNav
            title="Give Feedback"
            id="giveFeedback"
            icon={<MessageIcon className={classes.icon} />}
            dataMetrics={"cloudngfw-menu-give-feedback"}
          />
          <SubNavList
            isSimpleNav
            icon={<ProfileNavigationIcon size="md" />}
            title={userDisplayName}
            id={userDisplayName}
            url={`/${userDisplayName}`}
            dataMetrics={"cloudngfw-menu-user"}
          >
            <li className="tw-box-border">
              <a
                data-metrics={'cloudngfw-menu-user-logout'}
                className={classnames(classes.logoutButton, 'tw-text-xs tw-no-underline tw-text-blue-steel-300 tw-border-l-2 tw-flex tw-items-center tw-justify-between')}
                href="/login" onClick={handleLogout}>Logout</a>
            </li>
          </SubNavList>
        </BottomNav>
      </Navigation>
    </Box>
  );
};

export default Menu;
